<template>
  <section>
    <PageMetadata />
    <flash-alert v-model:show="flashAlert.show" :level="flashAlert.level">
      <span v-html="flashAlert.msg"></span>
    </flash-alert>
    <p class="regular-p">
      Your search for "{{ searchString }}" returned the following results
    </p>
    <h1 class="header px-0">{{ getMetaDetails.page_h1 }}</h1>
    <div
      class="content_below_heading"
      v-if="!!getMetaDetails.description"
      v-html="getMetaDetails.description"
    ></div>
    <tabs-js :tabs="tabs" :key="searchString">
      <template v-slot:shop>
        <shop @shopTotal="tabCount($event, 'shop')" :embed="true" />
      </template>
      <template v-slot:flowerlibrary>
        <flower-library
          @flowerTotal="tabCount($event, 'flowerlibrary')"
          :embed="true"
        />
      </template>
      <template v-slot:dutch>
        <dutch-direct-boxlots
          @dutchTotal="tabCount($event, 'dutch')"
          :embed="true"
        />
      </template>
      <template v-slot:hardgoods>
        <hard-goods
          @hardgoodsTotal="tabCount($event, 'hardgoods')"
          :embed="true"
        />
      </template>

      <template v-slot:blog v-if="showBlogPost">
        <blog
          @blogTotal="tabCount($event, 'blog')"
          :embed="true"
        />
      </template>
    </tabs-js>
    <recently-viewed />
  </section>
</template>


<script>
import { TabsJs, RecentlyViewed, FlashAlert, PageMetadata } from "@/components";
import { mapState, mapMutations } from "vuex";
import FlowerLibrary from "./FlowerLibrary.vue";
import Blog from "./Blog.vue";
import Shop from "./Shop.vue";
import DutchDirectBoxlots from "./DutchDirectBoxlots.vue";
import HardGoods from "./HardGoods.vue";
import _ from "lodash";
import axios from "axios";
import { misc } from "@/mixins";

export default {
  name: "SearchPage",
  components: {
    TabsJs,
    Shop,
    FlowerLibrary,
    RecentlyViewed,
    DutchDirectBoxlots,
    HardGoods,
    Blog,
    FlashAlert,
    PageMetadata,
  },
  data() {
    return {
      searchString: "",
      showBlogPost: false,
      tabsToBeLoaded: 4,
      removedProgramTabs: [],
      tabs: [
        { label: "Shop (0)", id: "shop" },
        {
          label: "Dutch Direct Boxlots (0)",
          id: "dutch",
          tabClass: "dutch-direct-boxlots-links",
        },
        {
          label: "Hardgoods (0)",
          id: "hardgoods",
          tabClass: "hardgoods-links",
        },
        { label: "Flower library (0)", id: "flowerlibrary" },
        
      ],
      countLoaded: [],
    };
  },
  mixins: [misc],
  mounted() {
    this.canShowBlogPost()
    this.updatePrograms();
    localStorage.setItem("currentPage", "search");
    let tab = this.tabs[0];
    if (this.$route.hash) {
      const hash = this.$route.hash.substring(1);
      tab = _.find(this.tabs, ["id", hash]);
    } else {
      this.updateHash(tab.id);
    }
    
    this.changeTab_s(tab.id);

    this.emitter.on("flash", (data) => {
      this.showFlashAlert(data.msg, data.level);
    });
    this.fetchMetaDetails();
    this.addSearchToGA()
  },
  unmounted() {
    this.emitter.off("flash");
  },
  computed: {
    ...mapState(["activeTab"]),
  },
  methods: {
    ...mapMutations({ changeTab_s: "changeTab" }),
    canShowBlogPost() {
      let val;
      let all_site_settings = JSON.parse(localStorage.getItem("site_settings"));
      val = all_site_settings.blog_display_search
        ? all_site_settings.blog_display_search
        : 0;
        let blogTab = {
              label: "Blog (0)",
              id: "blog",
              tabClass: "blog-links",
            } 
      if (val == 1 ) {
          this.showBlogPost = true
          this.tabs.push(blogTab)
          this.tabsToBeLoaded = this.tabsToBeLoaded + 1
          let tab = {}
          if (this.$route.hash) {
            const hash = this.$route.hash.substring(1);
            tab  = _.find(this.tabs, ["id", hash]);
            if (tab)
            this.changeTab_s(tab.id);
          }

      } else {
        this.showBlogPost = false
      }  
      
    },

    tabCount(data, tabId) {
      const tab = _.find(this.tabs, ["id", tabId]);
      const label = tab.label.split("(");
      tab.label = `${label[0]} (${data.count})`;
      tab["count"] = data.count;

      if (!this.countLoaded.includes(tabId)) {
        this.countLoaded.push(tabId);
      }
      if (this.countLoaded.length == this.tabs.length) {
        this.switchTabSelection();
      }
    },
    updateHash(tabId) {
      this.$router.replace({
        name: "Search",
        query: this.$route.query,
        hash: `#${tabId}`,
      });
    },

    switchTabSelection() {
      let idx = _.findIndex(this.tabs, ["id", this.activeTab]);
      const activeTab = this.tabs[idx];
      if (!activeTab.count) {
        let switchTabTo;
        for (let i = 0; i < this.tabs.length; i++) {
          const tab = this.tabs[i];
          if (tab.count) {
            switchTabTo = tab.id;
            break;
          }
        }

        if (!switchTabTo || switchTabTo == undefined) {
          const firstTabId = this.tabs[0].id;
          if (firstTabId !== activeTab.id) {
            switchTabTo = firstTabId;
          }
        }

        if (switchTabTo && switchTabTo != undefined) {
          this.changeTab_s(switchTabTo);
        }
      }
    },

    updatePrograms() {
      if (this.isLoggedIn) {
        axios
          .post(
            process.env.VUE_APP_API_BASE + "auth/getProgramStatuses",
            {},
            {
              headers: {
                "Content-type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            this.programClasses = response.data.classes;
            setTimeout(() => {
              this.programClasses.forEach((program) => {
                if (program.status == 0) {
                  let elements = document.querySelectorAll(
                    "." + program.css_class
                  );
                  if (elements.length > 0) {
                    const tab = _.find(this.tabs, ["tabClass", program.css_class]);
                    let checkRemoved = this.removedProgramTabs.includes(program.css_class)
                    if (!checkRemoved && tab) {
                      this.removedProgramTabs.push(program.css_class)
                    }
                    elements.forEach((element) => {
                      element.style.display = "none";
                    });
                  }
                }
              });
              this.tabsToBeLoaded = this.tabsToBeLoaded - this.removedProgramTabs.length
            }, 1000);
          });
      }
    },
    addSearchToGA() {
      let searchTerm = {
        search_term: this.searchString
      } 
      this.$gtag.event("search", searchTerm);
    },
  },
  watch: {
    activeTab(tabId) {
      this.updateHash(tabId);
    },
    "$route.query.search": {
      handler: function (value) {
        if (typeof value !== 'undefined') {
          this.searchString = decodeURIComponent(value);
          this.updatePrograms();
        }
      },
      immediate: true,
    },
  },
};
</script>